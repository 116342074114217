import React from 'react';
import { Box, Center, Text, Heading, Button } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';

import useInvitation from '@/hooks/useInvitation';
import txtWording from './locales';

import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { BG_FILTER } from '@/constants/assets';

/**
 * function to render Filter
 * @returns {JSX.Element}
 */
function FilterSection() {
  const isInvitation = useInvitation();
  
  const handleClickFilterIG = () => {
    window.open('https://www.instagram.com/ar/654373306885018/', '_blank');
  };

  return (
    <Box>
      {isInvitation && (
        <Box>
          <WithAnimation>
            <Heading fontSize="5xl" textAlign="center" color="secondaryColorText" margin="50px 0 12px" fontWeight="normal">
              Instagram <br />Wedding Filter
            </Heading>
          </WithAnimation>
          <WithAnimation>
            <Text align="center" color="secondaryColorText" marginBottom="16px" padding="0 24px 0 24px">
              <span dangerouslySetInnerHTML={{ __html: txtWording.subTitle[lang] }} />
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Box margin="24px 0 24px">
              <Center>
                <Image src={BG_FILTER} maxW="80%" borderRadius="16px" boxShadow="md" />
              </Center>
            </Box>
          </WithAnimation>
          <WithAnimation>
            <Center marginBottom="12px">
              <Button
                leftIcon={<FaInstagram />}
                size="sm"
                fontWeight="light"
                bgColor="mainColorText"
                color="secondaryColorText"
                colorScheme="blackAlpha"
                onClick={handleClickFilterIG}
              >
                {txtWording.textButton[lang]}
              </Button>
            </Center>
          </WithAnimation>
        </Box>
      )}
    </Box>
  );
}

export default React.memo(FilterSection);