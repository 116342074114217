import React from 'react';
import { Box, Heading, Text, AspectRatio } from '@chakra-ui/react';

import Image from '@/components/Common/LazyImage';
import WithAnimation from '@components/Common/WithAnimation';
import FilterSection from '@/components/Section/Filter'

import { COVER_GALLERY_1, COVER_GALLERY_2 } from '@/constants/assets';
import { ENABLE_WEDDING_FILTER, ENABLE_VIDEO_ALTERNATIVE, } from '@/constants/feature-flags';

import Gallery from './Gallery';

/**
 * function to render Photo Slider
 * This component using `react-image-gallery`
 * @returns {JSX.Element}
 */
function PhotoSliderSection({...rest}) {
  return (
    <Box>
      <Box bgColor="mainColor" padding="32px" {...rest}>
        <Box>
          <Heading
            textAlign="center"
            color="secondaryColorText"
            margin="16px 0 -16px"
            fontSize="4xl"
            fontWeight="bold"
          >
            {`The One & The Last`}
          </Heading>
          <Box padding="32px 0">
            <Text textAlign="center" fontSize="lg" color="secondaryColorText" padding="0 16px">
              <i>{`“I love thee with a love that shall not die, till the sun grows cold and the stars grow old.”`}</i>
            </Text>
            <Text textAlign="center" fontSize="lg" color="secondaryColorText" padding="0 16px">
              - William Shakespeare -
            </Text>
          </Box>
        </Box>
        <Box>
          {ENABLE_VIDEO_ALTERNATIVE && (
            <WithAnimation>
              <AspectRatio maxW="100%" ratio={16 / 9} borderRadius="16px" marginBottom="16px">
                <iframe
                  src="https://www.youtube.com/embed/tuS1LuPYQ_Q"
                  allowFullScreen
                  loading="lazy"
                  style={{ borderRadius: '16px' }}
                />
              </AspectRatio>
            </WithAnimation>
          )}
          <WithAnimation>
            <Image src={COVER_GALLERY_1} borderRadius="16px" marginBottom="16px" />
          </WithAnimation>
          {false && (
            <WithAnimation>
              <Image src={COVER_GALLERY_2} borderRadius="16px" marginBottom="16px" />
            </WithAnimation>
          )}
          <WithAnimation>
            <Box>
              <Gallery />
            </Box>
          </WithAnimation>
        </Box>
        {ENABLE_WEDDING_FILTER && (
          <FilterSection />
        )}
      </Box>
    </Box>
  );
}

export default React.memo(PhotoSliderSection);
